import { useCallback, useEffect, useState } from 'react';
import { format as fnsFormat, isValid } from 'date-fns';
import { pl } from 'date-fns/locale';

// USE THIS HOOK ONLY WHEN YOU NEED SOME TRANSLATABLE FORMAT EX: EEEE -> MON, TUE ...
const useDateServiceWithLocale = () => {
  const [dateFnsLocale, setDateFnsLocale] = useState(pl);

  useEffect(() => {
    updateDateFnsLocale();
  }, [window.__localeId__, global.__localeId__]);

  const updateDateFnsLocale = useCallback(async () => {
    let locale = window.__localeId__ ?? global.__localeId__;

    switch (locale) {
      case 'cz':
        locale = 'cs';
        break;
      case 'en':
        locale = 'en-US';
        break;
      case 'no':
        locale = 'nb';
        break;
    }

    const localeToSet = await import(
      /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
      `date-fns/locale/${locale}/index.js`
    );

    setDateFnsLocale(localeToSet);
  }, [window.__localeId__, global.__localeId__]);

  const formatWithLocale = useCallback(
    (date = '', formatStr = 'yyyy-MM-dd', defaultDate = null) => {
      const dateObject = new Date(date);

      if (isValid(dateObject)) {
        return fnsFormat(dateObject, formatStr, {
          locale: dateFnsLocale,
        });
      }

      return defaultDate;
    },
    [dateFnsLocale]
  );

  const formatArrayWithLocale = useCallback(
    (array, formatStr = 'yyyy-MM-dd') =>
      array.map(date => formatWithLocale(date, formatStr)),
    [dateFnsLocale]
  );

  return { formatWithLocale, formatArrayWithLocale };
};

export default useDateServiceWithLocale;

import { useQuery } from '@tanstack/react-query';

const useAdditionalPrices = () => {
  const selectAdditionalPrices = data => data?.additionalPrices ?? {};
  const query = useQuery({
    queryKey: ['orderForm'],
    select: selectAdditionalPrices,
  });
  return query;
};

export default useAdditionalPrices;

import isNumber from 'lodash/isNumber';
import tw from 'twin.macro';

import useRenderPrice from '@hooks/useRenderPrice';
import { cssMerge } from '@utils/styleHelpers';

const PriceWrapper = ({
  beforeDiscount,
  afterDiscount,
  mayNegative = false,
  styles = {},
}) => {
  const renderPrice = useRenderPrice({ mayNegative });

  const twStyle = cssMerge({
    defaultCss: tw`flex items-center -m-1`,
    ...styles?.wrapper,
  });

  return (
    <div css={twStyle}>
      {isNumber(beforeDiscount) && beforeDiscount !== afterDiscount && (
        <div
          data-cy="priceWrapper__before-discount"
          tw="p-1 text-red-1 line-through"
        >
          {renderPrice(beforeDiscount)}
        </div>
      )}
      {isNumber(afterDiscount) && (
        <div data-cy="priceWrapper__after-discount" tw="p-1">
          {renderPrice(afterDiscount)}
        </div>
      )}
    </div>
  );
};

PriceWrapper.displayName = 'PriceWrapper';

export default PriceWrapper;

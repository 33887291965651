import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { isNaN } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import PriceWrapper from '@components/elements/PriceWrapper';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import useAdditionalPrices from '@hooks/useAdditionalPrices';
import useRenderPrice from '@hooks/useRenderPrice';

import Omnibus from '../BasketDiet/Omnibus';

const DayAdditionalMealTypesList = ({
  mealTypes,
  day,
  handleAddAdditionalMealTypes,
  handleChangeMealTypesQuantity,
  connectedToIri,
  currentDiet,
}) => {
  const priceContainer = mealTypes
    ?.map(el => el.price.priceParts.CONTAINER)
    .reduce((acc, option) => acc + option, 0);

  const { data } = useAdditionalPrices();
  const validatePriceContainer = data?.CONTAINER?.cost;

  const priceEcoContainer = currentDiet.useEcoContainers
    ? mealTypes
        .map(el => el.price.priceParts.OPTION_ECO_CONTAINERS)
        .reduce((acc, option) => acc + option, 0)
    : 0;

  const { t } = useTranslation();
  const renderPrice = useRenderPrice({});
  const basket = useSelector(state => {
    return { basketEditOrder: state.basketEditOrder, myDiet: state.myDiet };
  });

  if (isEmpty(mealTypes)) return null;

  return (
    <div tw="text-sm py-2">
      {mealTypes.map(
        ({ mealTypeIri, price, name, quantity, past30DaysLowestPrice }) => {
          return (
            <Fragment key={mealTypeIri}>
              <div tw="flex justify-between -my-1.5 items-center flex-auto">
                <div tw="pr-2 py-1">{`${t(
                  '$*dayAdditionalMealTypesList.additionalPortion',
                  'Dodatkowa porcja:'
                )} ${name}`}</div>
                <div tw="flex -mx-2 py-1.5 items-center shrink-0">
                  {price.priceParts.OPTION_ECO_CONTAINERS ? (
                    <PriceWrapper
                      beforeDiscount={
                        price.priceParts.OPTION_ECO_CONTAINERS
                          ? price.beforeDiscount -
                            price.priceParts.OPTION_ECO_CONTAINERS
                          : price.beforeDiscount
                      }
                      afterDiscount={
                        price.priceParts.OPTION_ECO_CONTAINERS
                          ? price.afterDiscount -
                            price.priceParts.OPTION_ECO_CONTAINERS
                          : price.afterDiscount
                      }
                      styles={{ wrapper: { css: tw`pl-2` } }}
                    />
                  ) : (
                    <PriceWrapper
                      beforeDiscount={
                        price.priceParts.CONTAINER
                          ? price.beforeDiscount - price.priceParts.CONTAINER ??
                            0
                          : price.beforeDiscount
                      }
                      afterDiscount={
                        price.priceParts.CONTAINER
                          ? price.afterDiscount - price.priceParts.CONTAINER ??
                            0
                          : price.afterDiscount
                      }
                      styles={{ wrapper: { css: tw`pl-2` } }}
                    />
                  )}
                  <div tw="px-2">
                    <CheckboxCounter
                      count={quantity}
                      min={0}
                      max={99}
                      onChange={(_, quantity) => {
                        if (connectedToIri !== 'notConnected') {
                          handleAddAdditionalMealTypes({
                            quantity,
                            days: [day],
                            mealType: mealTypeIri,
                            connectedToIri,
                          });
                        } else {
                          handleChangeMealTypesQuantity({
                            dishIri: mealTypeIri,
                            quantity,
                            day: [day][0],
                            dietIri: basket.myDiet.selectedDiet['@id'],
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <Omnibus
                past30DaysLowestPrice={past30DaysLowestPrice}
                price={price.beforeDiscount}
              />
            </Fragment>
          );
        }
      )}

      {currentDiet.useEcoContainers
        ? !isNaN(priceEcoContainer) && (
            <div tw="flex justify-between">
              <span>
                {t(
                  '$*dayAdditionalMealTypesList.ecoContainers',
                  'Eko opakowania'
                )}
              </span>
              <span> {renderPrice(priceEcoContainer)}</span>
            </div>
          )
        : validatePriceContainer !== null && (
            <div tw="flex justify-between">
              <span>
                {t('$*components.basketPriceParts.containers', 'Pojemniki')}
              </span>
              <span> {renderPrice(priceContainer)}</span>
            </div>
          )}
    </div>
  );
};

export default withMemoBasketMethods(DayAdditionalMealTypesList, [
  'handleAddAdditionalMealTypes',
  'handleChangeMealTypesQuantity',
  'currentDiet',
]);

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Checkbox = ({
  label,
  styles = {},
  value = false,
  required = false,
  disabled = false,
  ...checkboxProps
}) => {
  const twStyle = {
    label: cssMerge({
      defaultCss: [
        tw`flex`,
        disabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`,
      ],
      ...styles?.label,
    }),
    input: cssMerge({
      defaultCss: [
        tw`w-5 h-5 rounded-md text-primary focus-visible:(ring-primary) mr-3`,
        disabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`,
      ],
      ...styles?.input,
    }),
    span: cssMerge({
      defaultCss: tw`cursor-pointer`,
      ...styles?.span,
    }),
  };

  return (
    <label css={twStyle.label}>
      <input
        type="checkbox"
        css={twStyle.input}
        checked={Array.isArray(value) ? value?.includes('on') : value}
        disabled={disabled}
        {...checkboxProps}
      />
      {!isEmpty(label) && (
        <span css={twStyle.span}>
          {label} {required && '*'}
        </span>
      )}
    </label>
  );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;

const WarningIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 99 87"
  >
    {title && <title>{title}</title>}
    <path d="M54.239 2.92A5.826 5.826 0 0049.187 0a5.826 5.826 0 00-5.052 2.92L.782 78.01a5.827 5.827 0 000 5.83 5.847 5.847 0 005.052 2.92H92.54a5.848 5.848 0 005.052-2.92 5.827 5.827 0 000-5.83L54.239 2.92zm-5.052 2.91L92.54 80.92H5.834L49.187 5.83zm0 59.29a5.226 5.226 0 015.226 5.22c0 2.89-2.342 5.23-5.226 5.23a5.226 5.226 0 110-10.45zm0-37.41a3.869 3.869 0 00-3.871 3.87v24.93c0 2.13 1.733 3.87 3.871 3.87a3.875 3.875 0 003.871-3.87V31.58c0-2.14-1.733-3.87-3.871-3.87z" />{' '}
  </svg>
);

export default WarningIcon;

import { useTranslation } from 'next-i18next';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import Collapse from '@components/elements/Collapse';
import useAdditionalPrices from '@hooks/useAdditionalPrices';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useToggle from '@hooks/useToggle';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

const BasketPriceParts = ({ priceParts }) => {
  const { t } = useTranslation();
  const { data } = useAdditionalPrices();
  const validatePriceContainer = data?.CONTAINER?.cost;

  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const keyTranslations = {
    DISH_PRODUCT: t('$*components.basketPriceParts.dishProduct', 'Danie'),
    OPTION_ECO_CONTAINERS: t(
      '$*components.basketPriceParts.ecoContainer',
      'Eko opakowanie'
    ),
    CONTAINER: t('$*components.basketPriceParts.container', 'Pojemnik'),
  };
  if (Object.keys(priceParts).length <= 1) {
    return null;
  }

  return (
    <div tw="border-b overflow-hidden pb-2">
      <motion.header initial={false} onClick={toggleIsExpanded}>
        <div tw="flex justify-between cursor-pointer pt-1">
          <div>
            {t(
              '$*components.basketPriceParts.pricePartsInside',
              'Co wchodzi w skład ceny?'
            )}
          </div>
          <div>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
      </motion.header>

      <Collapse isExpanded={isExpanded}>
        <ul tw="list-disc list-inside">
          {Object.entries(priceParts).map(([key, value]) => {
            if (key === 'CONTAINER' && validatePriceContainer === null) {
              return null;
            }

            const translation = keyTranslations[key];

            return (
              <li key={key}>
                {!!translation && `${translation} - `}
                {getIntegerIfIntegerOrFixed(value)} {currencySymbol}
              </li>
            );
          })}
        </ul>
      </Collapse>
    </div>
  );
};

export default BasketPriceParts;

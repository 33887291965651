import { AnimatePresence, motion } from 'framer-motion';

const motionProps = {
  open: {
    opacity: 1,
    height: 'auto',
    transitionEnd: {
      display: 'block',
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transitionEnd: {
      display: 'none',
    },
  },
};

const Collapse = ({ children, isExpanded = false, styles = {} }) => {
  return (
    <AnimatePresence initial={false}>
      {isExpanded ? (
        <motion.section
          key="content"
          initial={motionProps.closed}
          animate={motionProps.open}
          exit={motionProps.closed}
          transition={{ duration: 0.3 }}
          aria-expanded={isExpanded}
          css={styles}
        >
          {children}
        </motion.section>
      ) : null}
    </AnimatePresence>
  );
};

Collapse.displayName = 'Collapse';

export default Collapse;

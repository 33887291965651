import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';

import DayDishItem from './DayDishItem';

const DayDishesList = ({
  connectedToIri,
  day,
  dietIri,
  dishes,
  handleChangeProductQuantity,
}) => {
  return dishes.map(({ dish, sizes }) => {
    return (
      <div tw="text-sm py-2" key={dish['@id']}>
        <p tw="mb-0 pb-1 text-primary font-medium">{dish.nameForClient}</p>
        {sizes.map(({ '@id': iri, name, kcal, quantity, price, dishSize }) => {
          return (
            <DayDishItem
              key={iri}
              name={name}
              kcal={kcal}
              quantity={quantity}
              price={price}
              handleChangeQuantity={newQuantity => {
                handleChangeProductQuantity({
                  dishIri: dishSize['@id'],
                  quantity: newQuantity,
                  day,
                  dietIri,
                  connectedToIri,
                });
              }}
            />
          );
        })}
      </div>
    );
  });
};

export default withMemoBasketMethods(DayDishesList, [
  'handleChangeProductQuantity',
]);

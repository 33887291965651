import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import PriceWrapper from '@components/elements/PriceWrapper';

import BasketPriceParts from '../BasketPriceParts';

const DayDishItem = ({ kcal, name, price, quantity, handleChangeQuantity }) => {
  const { t } = useTranslation();

  return (
    <div tw="mb-2">
      <div tw="flex justify-between -my-1.5 items-center">
        <div>
          {name}
          {kcal && ` (${kcal} ${t('$*common.kcal', 'kcal')})`}
        </div>
        <div tw="flex -m-2 py-1.5 items-center">
          <PriceWrapper
            beforeDiscount={price.beforeDiscount}
            afterDiscount={price.afterDiscount}
            styles={tw`p-2`}
          />
          <div tw="p-2">
            <CheckboxCounter
              count={quantity}
              min={0}
              max={99}
              onChange={(_, newQuantity) => {
                handleChangeQuantity(newQuantity);
              }}
            />
          </div>
        </div>
      </div>
      <BasketPriceParts priceParts={price.priceParts} />
    </div>
  );
};

export default DayDishItem;

import tw from 'twin.macro';

import CheckboxCounter from '@components/elements/Form/CheckboxCounter';
import PriceWrapper from '@components/elements/PriceWrapper';

const DayAddonItem = ({ name, price, quantity, onCounterChange }) => {
  return (
    <div tw="flex justify-between -my-1.5 items-center flex-auto">
      <p tw="mb-0 pr-2 py-1">{name}</p>
      <div tw="flex -mx-2 py-1.5 items-center shrink-0">
        <PriceWrapper
          beforeDiscount={price.beforeDiscount}
          afterDiscount={price.afterDiscount}
          styles={{ wrapper: { css: tw`pl-2` } }}
        />
        <div tw="px-2">
          <CheckboxCounter
            count={quantity}
            min={0}
            max={99}
            onChange={(_, newQuantity) => {
              onCounterChange(newQuantity);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DayAddonItem;

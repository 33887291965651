import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import DayAddonItem from '@components/modules/Basket/BasketNormal/BasketDay/DayAddonItem';

import Omnibus from '../BasketDiet/Omnibus';

const DayAddonsList = ({ addons, day, dietIri, handleChangeAddonQuantity }) => {
  if (isEmpty(addons)) return null;

  return (
    <div tw="text-sm py-2">
      {addons.map(
        ({ '@id': addonIri, price, name, quantity, past30DaysLowestPrice }) => (
          <Fragment key={addonIri}>
            <DayAddonItem
              name={name}
              price={price}
              quantity={quantity}
              onCounterChange={quantity => {
                handleChangeAddonQuantity({
                  addonIri,
                  quantity,
                  day,
                  dietIri,
                });
              }}
            />
            <Omnibus
              past30DaysLowestPrice={past30DaysLowestPrice}
              price={price.beforeDiscount}
            />
          </Fragment>
        )
      )}
    </div>
  );
};

export default withMemoBasketMethods(DayAddonsList, [
  'handleChangeAddonQuantity',
]);

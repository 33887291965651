import isEmpty from 'lodash/isEmpty';

import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import { sortObjectByKeys } from '@utils/helpers';

import BasketDay from './BasketDay/BasketDay';

const BasketDietDay = ({
  days = [],
  dietIri = 'noDiet',
  connectedToIri = 'notConnected',
  basketErrors,
  isExpeandedByDefault = true,
}) => {
  const { isShop } = useAppMode();

  return (
    !isEmpty(days) &&
    Object.entries(sortObjectByKeys(days)).map(
      ([date, { price, dishes, mealTypes, delivery, addons }]) => {
        return (
          <BasketDay
            isExpeandedByDefault={isExpeandedByDefault}
            isConnectedElement={true}
            connectedToIri={connectedToIri}
            key={date}
            day={new Date(date)}
            dietIri={dietIri}
            dayPrice={price}
            addons={addons}
            mealTypes={Object.values(mealTypes)}
            dishes={Object.values(dishes)}
            delivery={delivery}
            isShop={isShop}
            isValidDayRequirements={
              isShop && !isEmpty(basketErrors[connectedToIri])
                ? basketErrors?.[connectedToIri]?.[dietIri]?.[date]?.isValid ??
                  true
                : true
            }
            errorMessageDayRequirements={
              isShop && !isEmpty(basketErrors[connectedToIri])
                ? basketErrors?.[connectedToIri]?.[dietIri]?.[date]
                    ?.errorMessage ?? null
                : null
            }
          />
        );
      }
    )
  );
};

export default withMemoBasketMethods(BasketDietDay, [
  { as: 'basketErrors', path: 'basketStore.errors' },
]);

import { Trans } from 'next-i18next';

import {
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { getIntegerIfIntegerOrFixed, isFalsify } from '@utils/helpers';

const Omnibus = ({ past30DaysLowestPrice, price }) => {
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { omnibusEnabledForDefaultPrices } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  if (
    !(
      omnibusEnabledForDefaultPrices &&
      !isFalsify(past30DaysLowestPrice) &&
      getIntegerIfIntegerOrFixed(past30DaysLowestPrice) <
        getIntegerIfIntegerOrFixed(price)
    )
  ) {
    return null;
  }

  return (
    <div tw="text-xs text-right">
      <Trans
        i18nKey="$*commmon.omnibus.past30DaysLowestPrice"
        defaults="Najniższa cena w ciągu ostatnich 30 dni: <0>{{ price }} {{ currencySymbol }}</0>"
        values={{
          price: getIntegerIfIntegerOrFixed(past30DaysLowestPrice),
          currencySymbol,
        }}
        components={[<span key="0" tw="font-bold" />]}
      />
    </div>
  );
};

export default Omnibus;

import { useCallback } from 'react';

import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import {
  selectMultinational,
  useAppConfigSelector,
} from './useAppConfigSelectors';

const useRenderPrice = ({ mayNegative = false } = {}) => {
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const renderPrice = useCallback(
    price => {
      if (mayNegative) {
        return `${price} ${currencySymbol}`;
      }

      return `${
        price < 0 ? 0 : getIntegerIfIntegerOrFixed(price)
      } ${currencySymbol}`;
    },
    [mayNegative]
  );

  return renderPrice;
};

export default useRenderPrice;
